@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './thema/_color.scss';
@import './thema/_override.scss';

* {
  font-family: Poppins;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

// ============== GLOBALS STYLES ==============

// Editor stylies
.quill {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // Solved bug with strict js mode
  /* > .ql-toolbar:nth-child(1) {
    display: none;
  } */

  > div {
    background-color: white;
  }
  .ql-container {
    overflow: auto;
  }
}

// Modal styles
.ant-modal-wrap {
  display: flex;
  .ant-modal:not(.ant-fast-modal) {
    .ant-modal-content {
      height: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: var(--border-radius);
      overflow: hidden !important;
      .ant-modal-confirm-content {
        max-width: 100% !important;
      }
      .ant-modal-body {
        flex-grow: 1;
        overflow: auto;
      }
    }
  }
}
.ant-modal-footer {
  .ant-btn {
    box-shadow: none;
  }
}

// Modal confirm style
.fast-modal {
  top: 0;
  margin: auto;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}

.ant-badge-count {
  z-index: 2;
}

.ctw-eb-window {
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    .ectComponent {
      height: 100%;
      overflow: auto;
      .embeddedBrowser {
        height: 100% !important;
        max-height: none !important;
        .browser {
          height: 100% !important;
          max-height: none !important;
        }
      }
    }
  }
}

/* Change Autocomplete styles in Chrome*/
input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  color: none !important;
  background-image: none !important;
  appearance: unset !important;
}
