.common {
  background-color: var(--white);
  border-radius: var(--border-radius);
  width: fit-content;
  padding: 3px var(--padding);
  &:not(.disabled) {
    cursor: pointer;
  }
  display: flex;
  gap: 10px;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
}

.addresses {
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;

  .addresses_list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .address {
      @extend .common;

      &.selected{
        border: 1px solid var(--color-primary);
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .add_address {
    @extend .common;
  }

  .delete_address {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;

    > div {
      cursor: pointer;
      color: #d94141;
      &:hover {
        opacity: 0.3;
      }
    }
  }
}
