@import '../../thema/_common.scss';
.init_process {
  @include backgroundImage;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .content {
    gap: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 90%;
    min-width: 700px;
    margin: auto;

    h2,
    h3 {
      text-align: center;
      font: normal normal 300 32px/40px Poppins;
      color: var(--color-primary);
    }

    .steps_content {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: var(--padding);
      border-radius: var(--border-radius);
      text-align: center;
      width: 30%;
      justify-content: center;
      min-height: 410px;

      p {
        font: normal normal 300 20px Poppins;
      }
    }
  }
}

.step_title {
  margin: 0;
  font: normal normal 400 19px Poppins;
}
