.record_item {
  width: 99%;
  margin: 0 auto;
  align-self: center;
  border-radius: 10px;
  min-height: 100px;
  display: flex;
  background: white;
  padding: var(--padding);
  flex-shrink: 0;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  flex-direction: row;
  position: relative;

  &:hover {
    background-color: var(--color-primary-hover);
  }

  .record_information {
    width: 100%;

    .record_information_specialty {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font: normal normal medium 14px/21px Poppins;
      letter-spacing: -0.28px;
      color: #fd6366;
    }

    .record_date {
      font: normal normal medium 16px/25px Poppins;
      letter-spacing: -0.32px;
      color: var(--font-color-1);
      opacity: 0.34;
    }

    .record_title {
      display: flex;
      gap: var(--gap);
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font: normal normal 600 24px/35px Poppins;
        letter-spacing: -0.48px;
        color: var(--font-color-1);
      }
    }
  }
  .record_chip_parent {
    display: flex;

    .record_chip {
      margin: auto;
      width: 100%;
      border-radius: 40px;
      background-color: var(--color-opacity-gray-light);
      height: 70px;
      overflow: hidden;
      padding-inline: var(--padding);
      :global {
        .ant-row {
          height: 100%;
          .ant-col {
            display: flex;
            height: fit-content;
            align-self: center;
            .ant-avatar {
              margin: auto;
              margin-left: 0;
              height: 50px;
              width: 50px;
            }
          }
        }
      }
      .record_professional_info {
        flex-direction: column;
        .record_professional_name {
          > div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font: normal normal medium 14px/21px Poppins;
            letter-spacing: -0.28px;
            color: var(--font-color-1);
          }
        }

        .record_professional_enrollment {
          font: normal normal medium 14px/21px Poppins;
          letter-spacing: -0.28px;
          color: var(--font-color-2);
        }
      }
    }
  }
}
