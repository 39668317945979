button.whatsapp {
  background-color: #128c7e !important;
  border-color: #128c7e !important;

  &:hover {
    color: white !important;
    opacity: 0.8;
  }
}
.col {
  display: flex;
  .button {
    margin: auto;
  }
}
