@import '../../thema/_common.scss';

.search {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  position: relative;
  @include backgroundImage;
  .img {
    background: transparent
      url('../../../public/assets/icons/search-icon-page.png') 0% 0% no-repeat
      padding-box;
    background-size: cover;
    mix-blend-mode: luminosity;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 310px;
    width: 430px;
  }
}
