@import '../../thema/_common.scss';

.people {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include backgroundImage;

  .content {
    @include common_width;
    height: 96%;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .main {
      background-color: #ebf1f4;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}
