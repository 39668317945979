.common {
  display: flex;
  padding: var(--padding);
  flex-direction: column;
  height: 100%;
  gap: var(--gap);
}

.disabledInput {
  background-color: var(--white) !important;
  border-radius: 10px;
  color: black !important;
  cursor: default !important;
  * {
    cursor: default !important;
    color: black !important;
  }
}

.record {
  @extend .common;
  overflow: hidden;

  .action {
    display: flex;
    align-items: center;
  }
  .record_last_row {
    justify-content: space-between;
    .record_files_col {
      height: 64px;
    }

    .record_action_col {
      display: flex;
      justify-content: flex-end;
      :global {
        .ant-row {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }

  :global {
    .ant-select {
      width: 100%;
    }

    .ant-picker {
      width: 100%;
    }

    .ant-btn {
      width: 100%;
    }
  }
}
.error_message {
  @extend .common;
  justify-content: center;
  align-items: center;
}
