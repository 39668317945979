@import '../../thema/_common.scss';

.profile_layout {
  @include common_width;
  display: flex;
  overflow: hidden;
  background-color: #ebf1f4;
  height: 96%;
  max-width: 1500px;
  margin: auto;
  border-radius: var(--border-radius);

  .box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--gap);

    .main_content {
      height: 100%;
      padding-inline: 20px;
      padding-top: 30px;
      padding-bottom: 0;
      overflow: hidden;
      gap: var(--gap);
      flex-wrap: nowrap;

      .photo {
        display: flex;
        justify-content: center;
        height: fit-content;
      }
      .form {
        height: 100%;
        display: flex;
        overflow: hidden auto;
        flex-direction: column;
      }
    }

    .buttons {
      margin-inline: 10px !important;
      margin-bottom: 10px !important;
    }
  }
}

.loading_screen {
  width: 100%;
}
