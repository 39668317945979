.editor {
  overflow: auto;
  height: 100%;
  :global {
    .ant-col {
      overflow: hidden;
      height: 100%;
    }
  }
  .text_editor {
    height: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
  }
}

.counter {
  position: relative;
  > div {
    position: absolute;
    right: 0;
    &.max {
      color: red;
    }
  }
}

.hidden {
  :global {
    .ql-toolbar.ql-snow {
      display: none !important;
    }
  }
}
