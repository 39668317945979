.list {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
  gap: var(--gap-list);
}

.list,
.grid {
  .empty,
  .list_loading {
    width: 200px;
    height: 130px;
    margin: auto;
  }
  .list_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}


.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--card-width), 1fr));
  grid-gap: var(--gap-list);
  width: 100%;
  height: 100%;
  overflow: auto;
}
