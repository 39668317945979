.phone_field {
  .label {
    margin: 0px;
    margin-top: 5px;
  }

  :global {
    .ant-form-item {
      margin: 0;
    }
  }

  .phone {
    padding: 0;
  }

  .phone_code {
    :global {
      .ant-select-selection-item {
        padding: 0;
        text-align: center;
      }
    }
  }

  .advice {
    font-size: 8px;
    margin: 0;
    top: 26px;
    position: absolute;
    color: var(--color-primary);
  }
}
