@import url('https://icdcdn.who.int/embeddedct/icd11ect-1.5.css');

.icd {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .search {
    width: 50%;
    margin: auto;
    label {
      margin-bottom: 5px;
      display: block;
      :global {
        .ant-tag {
          margin-bottom: 5px;
        }
      }
      .tag_content {
        cursor: pointer;
        &:hover {
          > span {
            opacity: 0.6;
          }
        }
      }

      .label_search {
        color: white;
        margin: 0;
        font: normal normal 500 20px Poppins;
      }
    }
    .input_button {
      gap: var(--gap);
      display: flex;
      > button {
        box-shadow: none;
      }
    }
  }
  .content {
    overflow: hidden;
    flex-grow: 1;
    margin-top: 10px;
    border-radius: var(--border-radius);

    :global {
      .ctw-window,
      .ectComponent {
        height: 100%;
      }
      .ectComponent {
        > div:not(.browser):not(.embeddedBrowser) {
          height: 100%;
          > div {
            height: 100%;
            > div {
              height: 100%;
              > div:nth-child(2) {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
