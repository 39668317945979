.search_header {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  &.searched {
    position: relative;
    z-index: 1001 ;
  }
  :global {
    .ant-input-affix-wrapper,
    .ant-input {
      background-color: #148483 !important;
      color: white;
      font: normal normal medium 14px/21px Poppins;
      letter-spacing: -0.28px;

      input::-webkit-inner-spin-button {
        display: none !important;
      }
    }

    .ant-input-prefix {
      svg {
        height: 18px;
      }
    }

    .ant-input-suffix {
      opacity: 0.5;
    }
  }

  .close {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
