.avatar {
  display: flex;
  :global {
    .ant-avatar {
      cursor: pointer;
      margin: 0 auto auto 0;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
