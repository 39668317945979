.diagnostics_item {
  background-color: #f9f9f9;
  width: 250px;
  display: flex;
  border-radius: var(--border-radius);
  padding: var(--padding);
  box-shadow: var(--box-shadow);
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 280px;

  .clip_study {
    position: absolute;
    top: -6px;
    left: -6px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    > span {
      font-size: 1.1rem;
    }
  }

  .diagnostics_item_title {
    text-align: center;
    font-weight: bold;
    cursor: pointer;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &:hover {
      opacity: 0.8;
    }
  }

  .description {
    text-align: start;
    overflow: auto;
    padding: 5px;
    margin-bottom: auto;
    max-height: 187px;

    .description_text {
      min-height: 62px;
    }

    > div {
      position: relative;
      margin-bottom: 10px;
      background: white;
      padding: 5px;
      box-shadow: 1px 1px 1px rgb(204, 204, 204);
      border-radius: var(--border-radius);
      .empty_comment {
        :global {
          .ant-empty-image {
            height: 40px;
            margin: 0px;
          }
        }
      }
    }

    :global {
      .ant-empty {
        box-shadow: none;
        border-radius: 0px;
        margin: 0px;
        padding: 0px;
        height: 100%;
        width: 100%;
        background: local;
        margin: auto;
      }
    }

    .not_visible {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      > p {
        margin: 0;
        > div {
          display: inline;
          color: var(--color-primary);
          cursor: pointer;
          margin-left: 5px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    p {
      margin-bottom: 10px;
      font-size: 13px;
    }

    .small_information {
      font-size: 9px;
      text-align: end;
      margin-bottom: 5px;
    }
  }

  .information {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap) / 2);
    font-size: var(--font-size-smaller);
    text-align: end;
  }

  .created_by {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
    span svg {
      height: 15px !important;
    }
  }
}
