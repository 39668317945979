.speciality {
  flex-shrink: 0;
  background-color: inherit;
  border-radius: 10px;
  cursor: pointer;
  gap: 10px;
  display: flex;
  font-size: 16px;
  font-family: Poppins;

  &.on_record {
    font-size: 21px;
  }

  &:not(.without_border) {
    background-color: var(--white);
    padding: 1px 10px;
    &:hover {
      opacity: 0.8;
    }

    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    opacity: 0.5;

    &.selected {
      opacity: 1;
      border-color: rgb(152, 152, 152);
    }
  }

  .icon {
    height: 20px;
    width: 20px;
    margin: auto 0;
  }
}
