.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.list {
  display: flex;
  flex-flow: column nowrap;
  overflow: auto hidden;
  gap: var(--gap);
}
