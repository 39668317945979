.filter_content {
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  .filter_actions {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  :global {
    .ant-select {
      width: 100%;
    }

    .ant-btn {
      width: 100%;
    }

    .ant-segmented-item-selected {
      font: normal normal 600 14px/21px Poppins;
      letter-spacing: -0.28px;
      color: var(--font-color);
      background-color: var(--color-fourth) !important;
    }
  }
}
