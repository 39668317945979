.search_people {
  display: flex;
  width: 100%;
  height: 100%;

  .searching {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 80px;

    .text {
      font: normal normal 600 30px/46px Poppins;
      margin-bottom: 30px;
      letter-spacing: -0.6px;
      color: var(--color-third);
    }
    .icon {
      color: #03366b;
      font-size: 39px;
      justify-content: center;
    }
  }

  .not_exist {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 80px;
    width: 400px;

    h3 {
      font: normal normal 600 24px/35px Poppins;
      letter-spacing: -0.48px;
      color: var(--font-color-1);
      margin: 0;
    }
    p {
      font: normal normal medium 18px/27px Poppins;
      letter-spacing: -0.36px;
      color: var(--font-color-1);
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      button {
        width: fit-content;
        min-width: 170px;
        font: normal normal 600 16px/25px Poppins;
        letter-spacing: -0.32px;
      }
    }
  }
  .hints {
    opacity: 0.8;
    margin-top: var(--margin);
    font-size: var(--font-size-small);
  }
}
