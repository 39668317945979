.person_extra_information {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    > div {
      display: flex;
      gap: var(--gap);
      align-items: center;

      h4 {
        font-weight: bold;
        margin: 0;
      }

      &.action {
        cursor: pointer;
        width: fit-content;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .address {
    flex-direction: row;
    gap: var(--gap);
    > div {
      flex-direction: column;
      align-items: flex-start;
      > div {
        display: flex;
        gap: var(--gap);
      }
    }

    //Informatino
    > div:first-child {
      flex-shrink: 0;
      width: 50%;
    }

    //Map
    > div:nth-child(2) {
      max-height: 100%;
      width: 100%;
    }
  }
}
