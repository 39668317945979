.modal_files {
  padding: var(--padding);
  .clip_study {
    position: absolute;
    top: -6px;
    left: -6px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    > span {
      font-size: 1.1rem;
    }
  }

  .file {
    position: relative;
    background-color: #f9f9f9;
    height: fit-content;
    display: flex;
    width: 200px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--border-radius);
    padding: 5px;
    box-shadow: var(--box-shadow);
    margin: var(--margin);

    &.visible {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    .file_icon {
      margin: 10px auto;
      > span {
        font-size: 3rem;
      }
    }

    .not_visible {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      svg {
        width: 50px;
        height: 50px;
      }
      > p {
        margin: 0;
        > div {
          display: inline;
          color: var(--color-primary);
          cursor: pointer;
          margin-left: 5px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .file_name {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      p {
        word-break: break-all;
      }
    }

    .small_information {
      text-align: end;
      font-size: 9px;
    }
  }

  .modal_empty {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
  :global {
    .ant-modal-body {
      display: flex;
      gap: calc(var(--gap) + 5px);
      flex-wrap: wrap;
      overflow: auto;

      :global {
        .ant-badge {
          height: fit-content;
          margin-inline: auto;
        }
      }
    }
  }

  .files_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
  }
}

.created_by {
  font-size: 9px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
  span svg {
    height: 15px !important;
  }
}
