.profile_photo {
  :global {
    .ant-upload-list {
      display: flex;
      justify-content: center;
      .ant-upload {
        margin: 0;
      }
    }
  }
}
