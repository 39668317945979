@import '../../thema/_common.scss';

.header {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-primary);
  padding: var(--padding) 0;

  .medical_trace_logo {
    width: 175px;
    font: normal normal normal 24px/29px Telugu MN;
    letter-spacing: -1.2px;
    color: var(--font-color);
    text-align: center;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .search_header {
    width: 100%;
  }

  .currently_user {
    justify-content: center;
    min-width: 170px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: var(--gap);
    color: var(--font-color);
    padding-inline: 10px;
    :global {
      .ant-dropdown-trigger {
        display: flex;
        gap: var(--gap);
        align-items: center;
        color: var(--font-color);
      }
    }
  }
}
