@import '../../thema/_common.scss';

.professional_profile {
  @include backgroundImage;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  h3 {
    font: normal normal 500 16px Poppins;
    margin: 0;
  }

  h6 {
    margin: 0;
    margin-left: 10px;
    font: normal normal 500 10px Poppins;
  }

  :global {
    .ant-divider {
      margin: 10px 0;
    }
  }
}

.change_password_label {
  margin: 0;
  font-size: 11px;
  font-variant: all-small-caps;
  cursor: pointer;
  display: inline;
  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
  color: var(--color-primary);
  display: flex;
  gap: 5px;
  align-items: center;

  > span {
    height: 15px;
    width: 15px;

    path {
      fill: var(--color-primary);
    }
  }

  &:hover {
    opacity: 0.5;
  }
}
