@import '../../thema/_common.scss';

.not_found_page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include backgroundImage;
  .content {
    text-align: center;
  }
}
