.person_study_chip {
  border-radius: 40px;
  background-color: var(--color-opacity-gray-light);
  height: 70px;
  overflow: hidden;
  padding-inline: var(--padding);

  &.pointer {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .big {
    width: 60px;
    height: 60px;
  }
  :global {
    .ant-row {
      height: 100%;
      .ant-col {
        display: flex;
        height: fit-content;
        align-self: center;
        .ant-avatar {
          margin: auto;
          margin-left: 0;
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .person_info {
    flex-direction: column;

    .person_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 19px;
      letter-spacing: -0.28px;
      font-weight: 400;
    }

    .person_id {
      font: normal normal medium 14px/21px Poppins;
      letter-spacing: -0.28px;
      color: var(--font-color-2);
    }
  }

  .person_study_person_gender {
    margin-top: 13px;
    margin-bottom: auto;
    > div {
      display: flex;
      gap: var(--gap);
      align-items: center;
      font: normal normal medium 14px/21px Poppins;
      letter-spacing: -0.28px;
      color: var(--font-color-2);
    }
  }
}
