:root {
  //colors
  --white: white;

  --color-primary: #179b9a;
  --color-primary-hover: #fef9e9;

  --color-secondary: #f5f6fa;
  --color-third: #091f3e;

  --color-fourth: #03366b;

  --color-button-primary: #107c85;
  --color-button-secondary: #5b88eb;
  --color-button-third: #ef667c;
  --color-button-fourth: var(--color-third);

  --color-badge: var(--color-third);

  /*--color-tertiary: #4adede;
  --color-quaternary: #787ff6;
  --color-quinary: #7bd5f5; */

  --color-opacity-gray: #00000027;
  --color-opacity-gray-light: #f7f7f7;

  --card-color: #84bfdb;
  --box-shadow: 2px 2px 7px -2px black;

  // common measurement
  --border-radius: 10px;
  --padding: 10px;
  --margin: 10px;
  --gap: 10px;

  // font size
  --font-size-smaller: 10px;
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-large: 16px;

  --font-color: white;
  --font-color-1: var(--color-third);
  --font-color-2: #a0a5a8;
}
