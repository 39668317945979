.counter {
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: var(--border-radius);
  flex-direction: column;

  .number {
    margin-top: 10px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font: normal normal 600 30px/46px Poppins;
    letter-spacing: -0.6px;
  }

  .label {
    padding: 2px 0px 5px 0px;
    text-align: center;
    font: normal normal 600 10px/16px Poppins;
    letter-spacing: -0.2px;
  }
}
