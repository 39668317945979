@import '../../thema/_common.scss';

.person_profile {
  @include backgroundImage;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  h3 {
    margin: 0;
  }

  :global {
    .ant-divider {
      margin: 10px 0;
    }
  }
}
