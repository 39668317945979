.fab_button {
  z-index: 2;
  cursor: pointer;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: var(--color-third);
  position: absolute;
  box-shadow: 2px 3px 6px #00000029;
  border-radius: 50%;
  display: flex;
  &:hover {
    opacity: 0.8;
  }
  > span {
    margin: auto;
  }
}
