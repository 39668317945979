.actions {
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.5;
  }
}

.buttons {
  display: flex;
  gap: 20px;

  .disabled {
    opacity: 0.8;
    cursor: default;
  }

  > div {
    cursor: pointer;
    &:not(.disabled):hover {
      opacity: 0.8;
    }
  }
}
