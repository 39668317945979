.curren_user_component {
  width: 100%;
  display: flex;
  .current_user {
    display: flex !important;
    flex-flow: row nowrap !important;
    gap: var(--gap) !important;
    align-items: center;
    padding: 3px 10px;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

.current_user_open_popover {
  border-radius: 10px 10px 0 0;
  padding: 3px 10px;
  background: #00000027;
}

.current_user_open_popover_overlay {
  padding: 0px;
  :global {
    .ant-popover-inner {
      padding: 0;
      border-radius: 0px 0px 20px 20px;
      background: #ebf1f4;
    }
  }
}

.menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  margin-top: -8px;

  > div {
    cursor: pointer;
    gap: 5px;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    font-size: 13px/20px;
    font-family: Poppins;
    color: var(--color-fourth);
    margin: 5px 0;
    display: flex;
    flex-flow: column nowrap;
    &:hover {
      opacity: 0.8;
    }
  }
}
