.sub_title {
  p {
    color: red;
    display: inline;
  }
}

.divider {
  margin: 0px;
  margin-bottom: 10px;
}

.autogenerated_cuil {
  font-size: 10px;
  opacity: 0.8;
  top: 35px;
  position: absolute;
  width: 180px;
}
