@import '../../thema/_common.scss';

.person_study_item {
  @include common_width;
  box-sizing: border-box;
  min-width: 850px;
  margin: 0 auto;
  border-radius: 10px;
  min-height: 100px;
  display: flex;
  background: white;
  padding: var(--padding);
  gap: var(--gap);
  flex-shrink: 0;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  justify-content: center;

  &:hover {
    background-color: var(--color-primary-hover);
  }

  .person_study_row {
    width: 100%;

    .person_study_information,
    .person_study_person {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .person_study_specialty {
      font: normal normal medium 14px/21px Poppins;
      letter-spacing: -0.28px;
      color: #fd6366;
    }

    .person_study_date {
      font: normal normal medium 16px/25px Poppins;
      letter-spacing: -0.32px;
      color: var(--font-color-1);
      opacity: 0.34;
    }

    .record_title {
      display: flex;
      gap: var(--gap);
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font: normal normal 600 24px/35px Poppins;
        letter-spacing: -0.48px;
        color: var(--font-color-1);
      }
    }
  }
}
