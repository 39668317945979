@import '../../thema/_common.scss';

.more_options {
  position: absolute;
  right: 3px;
  top: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.more_options_text {
  background-color: var(--white);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .action {
    @include click;
    color: var(--color-primary);
  }
}
