.address {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  .label {
    margin-top: 5px;
    &.disabled {
      opacity: 0.5;
    }
  }

  :global {
    .ant-form-item {
      margin: 0;
    }
  }

  .contact_button {
    margin: 0;
    font-size: 11px;
    font-variant: all-small-caps;
    cursor: pointer;
    display: inline;
    float: right;
    margin-right: 10px;
    color: var(--color-primary);
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;

    &:hover {
      opacity: 0.5;
    }
  }

  .contact {
    margin-left: 10px;
  }
  .sub_title {
    margin: 0;
    margin-top: 10px;
    font: normal normal 500 12px Poppins;
  }

  .map{
    border-radius: var(--border-radius);
    overflow: hidden;
  }
}
