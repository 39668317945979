.birthdate_field {
  .label {
    margin: 0px;
    margin-top: 5px;
  }
  :global {
    .ant-form-item {
      margin: 0;
    }
  }
}
