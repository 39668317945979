.professional_information {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: var(--margin);
  margin-left: 10px;

  .specialities {
    display: flex;
    gap: var(--gap);
    overflow: auto hidden;
  }
  .speciality_detail {
    height: fit-content;
    width: 100%;
    display: flex;
    gap: var(--gap);
    > div {
      margin: 5px 0;
      flex-shrink: 0;
      background-color: var(--white);
      padding: 1px 10px;
      border-radius: 10px;
    }
  }
}
