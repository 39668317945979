.notification {
  display: flex;
  gap: var(--gap);
  padding: 10px;
  align-items: center;
  background: var(--color-secondary);
  border-radius: 10px;

  .notification_buttons {
    display: flex;
    justify-content: flex-end;
    gap: var(--gap);
  }
  &[data-disabled='true'] {
    opacity: 0.5;
  }
}
