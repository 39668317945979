.header {
  display: flex;
  background-color: var(--white);
  height: 100px;
  justify-content: space-between;
  padding: var(--padding);
  gap: var(--gap);
  box-shadow: var(--box-shadow);

  .person {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .counters {
    display: flex;
    gap: var(--gap);
  }
}
