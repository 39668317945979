.process {
  display: flex;
  gap: var(--gap);
  align-items: center;
  :global {
    .ant-progress-inner {
      height: 30px !important;
      width: 30px !important;
      .ant-progress-text {
        font-size: 12px !important;
      }
    }
  }
}
