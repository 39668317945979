.mt_button_hover {
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.mt_primary_button {
  background-color: var(--color-fourth) !important;
  border-radius: 20px;
  color: var(--font-color) !important;
  border-color: var(--color-fourth);
  @extend .mt_button_hover;
}

.mt_secondary_button {
  border-radius: 20px;
  background-color: white !important;
  color: var(--color-fourth);
  border-color: var(--color-fourth);
  @extend .mt_button_hover;
}

.mt_third_button {
  background: var(--color-button-third) !important;
  border-color: var(--color-button-third) !important;
  @extend .mt_button_hover;
}

.mt_fourth_button {
  color: white;
  background: var(--color-button-fourth) !important;
  border-color: var(--color-button-fourth) !important;
  @extend .mt_button_hover;
}

.mt_fifth_button {
  &.solid {
    background-color: var(--color-primary);
    color: var(--font-color);
    border-color: var(--color-primary);
    cursor: pointer;
  }
  &.outline {
    background-color: #d1ebeb;
    border-color: #d1ebeb;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    color: var(--color-primary);
  }
  @extend .mt_button_hover;
}

.mt_delete_button {
  @extend .mt_secondary_button;
  color: red;
  border-color: red;
}

.mt-badgets {
  display: flex;
  overflow: inherit !important;
  .ant-badge {
    display: flex;
    align-self: center;
  }
}
