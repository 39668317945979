.text {
  font: normal normal 400 14px/21px Poppins;
  letter-spacing: -0.28px;
}

.button,
.button > button {
  &:hover {
    opacity: 0.8;
  }
  width: 100%;
  background: var(--color-primary) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}
