.login {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: rgb(52, 168, 167);
  background: linear-gradient(
    90deg,
    rgba(52, 168, 167, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 20px;

  h4 {
    margin: 0;
    font: normal normal normal 25px/30px Telugu MN;
    letter-spacing: -0.64px;
    color: var(--white);
  }

  > div {
    display: flex;
    flex-direction: column;
    margin: auto 40px;

    .slogan,
    .sub_slogan,
    .more {
      width: fit-content;
      margin: 2px 0;
      color: var(--white);
    }

    .more {
      text-decoration: underline;
      font: normal normal 200 20px/30px Poppins;
      letter-spacing: -0.48px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    .slogan {
      font: normal normal 300 32px/40px Poppins;
    }
    .sub_slogan {
      font: normal normal medium 32px/40px Poppins;
    }
    .content {
      display: flex;
      margin: 0;
      margin-top: 10px;

      .form_box {
        margin: auto 0;
        width: 300px;
        height: fit-content;
        padding: 30px;
        background: var(--white) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 15px #00000029;
        border-radius: 10px;

        h4 {
          margin: 0;
          margin-bottom: 30px;
          text-align: center;
          font: normal normal normal 24px/40px Helvetica;
          letter-spacing: -0.48px;
          color: #091f3e;
        }

        .forgot_password {
          letter-spacing: -0.28px;
          margin: 0;
          margin-bottom: 20px;
          text-align: end;
          text-decoration: underline;
          font: normal normal 300 14px/20px Helvetica;
          color: var(--color-primary);
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }

      .button,
      .button > button {
        &:hover {
          opacity: 0.8;
        }
        width: 100%;
        background: var(--color-primary) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        opacity: 1;
      }

      .button {
        margin-bottom: 20px;
      }

      .terms {
        margin: 0;
        text-align: center;
        text-decoration: underline;
        font: normal normal 300 12px/25px Helvetica;
        letter-spacing: -0.24px;
        color: #091f3e;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .lottie_box {
        position: absolute;
        right: 20px;
        bottom: -40px;
      }
    }
  }

  .version {
    font-size: 10px;
    position: absolute;
    bottom: 2px;
    left: 10px;
  }
}
