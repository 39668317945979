@mixin backgroundImage {
  background-image: url('../../../public/assets/img/wavesOpacity.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: local;
  background-position-y: -1px;
}

@mixin click {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

@mixin common_width {
  width: calc(100% - 350px);
}
