.email_list {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  .email_row {
    background-color: var(--color-opacity-gray-light);
    padding: 5px var(--padding);
    border-radius: var(--border-radius);
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
