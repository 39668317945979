.loading {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  .icon {
    color: #03366b;
    font-size: 39px;
  }
}
