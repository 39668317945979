.person_summary_information {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: var(--gap);

  .photo {
    > span {
      height: 70px;
      width: 70px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    .text {
      display: flex;
      gap: var(--gap);
      h4 {
        font: normal normal 600 36px/55px Poppins;
        letter-spacing: -0.72px;
        margin: 0;
        color: var(--primary-color);
      }
      .actions {
        margin-left: 25px;
        display: flex;
        align-items: center;
      }
    }
    .id_gender_society {
      display: flex;
      gap: var(--gap);
      > div {
        display: flex;
        align-items: center;
        gap: var(--gap);
        width: fit-content;
        padding: 1px 10px;
        border-radius: var(--border-radius);
        background-color: #ebf1f4;

        font: normal normal medium 14px/21px Poppins;
        letter-spacing: -0.28px;
        color: #a0a5a8;
      }
    }
  }
}
