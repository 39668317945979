.terms-and-conditions-modal {
  border-radius: var(--border-radius);
  top: 0 !important;
  max-height: 80%;
  overflow: hidden;
  height: 100% !important;
  .ant-modal-content {
    overflow: hidden;

    .ant-modal-body {
      display: flex;
      height: 100%;
      flex-direction: column;
      overflow: hidden auto !important;
    }

    & .ant-row::-webkit-scrollbar {
      width: 1px;
    }
  }

  .continue-button {
    width: -webkit-fill-available;
    border-radius: 3px;
  }

  .check-box {
    .ant-checkbox .ant-checkbox-inner {
      border-color: rgb(187, 187, 187);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: rgb(187, 187, 187);
    }
  }
}
