@import '../../thema/_common.scss';

.home {
  height: 100%;
  width: 100%;
  overflow: hidden;

  /* list */
  > div:nth-child(1) {
    @include backgroundImage;

    > div {
      &:first-child {
        margin-top: auto;
      }
      &:last-child {
        margin-bottom: auto;
      }
    }
  }
}
