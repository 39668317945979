.empty_diagnostics {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .back_diagnostics {
    cursor: pointer;
    color: var(--card-color);
    &:hover {
      opacity: 0.7;
    }
  }
}

.comment {
  :global {
    .ant-input-disabled {
      background: local !important;
      color: black !important;
    }
  }
}

.panel_actions {
  display: flex;
  gap: var(--gap);
  align-items: center;
}

.button_diagnostics{
  font-size: 13px;
}
