.modal_antecedents {
  .modal_empty {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
  :global {
    .ant-modal-body {
      display: flex;
      gap: calc(var(--gap) + 5px);
      flex-wrap: wrap;
      overflow: auto;

      :global {
        .ant-badge {
          height: fit-content;
          margin-inline: auto;
        }
      }
    }
  }
}
