.record_files {
  display: flex;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  gap: var(--gap);

  .record_files_uploaded {
    display: flex;
    height: 100%;
    gap: var(--gap);
    overflow: auto;
    padding-right: 5px;
    justify-content: flex-start;
    flex-wrap: wrap;

    .file {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      background: white;
      padding: 2px;
      flex-shrink: 0;
      width: 100px;
      overflow: hidden;
      box-shadow: var(--box-shadow);
      border-radius: 5px;
      margin-bottom: 5px;

      .medical_study_file_icon {
        flex-grow: 1;
        flex-shrink: 0;
        > span {
          opacity: 0.7;
          font-size: 2rem;
        }
      }

      .medical_study_file_name {
        text-align: start;
        overflow: hidden;

        > p {
          text-overflow: ellipsis;
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      > div {
        justify-content: center;
        display: flex;
        font-size: var(--font-size-small);
      }
    }
    .medical_study_files_delete_button {
      &:hover {
        opacity: 0.8;
      }
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }

  .record_file_upload {
    display: flex;
    overflow: hidden; // vertical scroll

    //Edit default antd upload component
    :global {
      .ant-upload-select,
      .ant-upload-list-item-container {
        height: 100% !important;
        border-radius: var(--border-radius);
      }

      .ant-upload-select {
        color: var(--white);
        background: var(--color-fourth) !important;
      }

      .ant-upload-list-item-file {
        opacity: 0.4;
      }

      .ant-upload-list-picture-card {
        height: 100%;
        .ant-upload-select-picture-card {
          margin: 0px;
          height: 64px;
          padding-inline: 5px;
          width: fit-content;
        }
        .ant-upload-list-picture-card-container {
          height: 64px;
          margin-bottom: 10px;

          .anticon:not(.anticon-file) {
            vertical-align: -19px;
          }

          .anticon-file {
            vertical-align: 8px;
          }

          .ant-upload-list-item {
            padding: 4px;
            .ant-upload-list-item-name {
              bottom: -2px;
            }
          }
        }
      }
    }
  }
}
