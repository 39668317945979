.filters_records {
  display: flex;
  padding: var(--padding);
  justify-content: space-between;

  .actions {
    display: flex;
    gap: var(--gap);

    > button {
      border-radius: 20px;
      font: normal normal 600 14px/21px Poppins;
      letter-spacing: -0.28px;
      border-color: var(--color-fourth);

      &:hover {
        opacity: 0.8;
      }
    }

    > button:nth-child(1) {
      color: var(--color-fourth);
    }

    .add {
      background-color: var(--color-fourth);
      color: var(--font-color);
    }
  }
}
